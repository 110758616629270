.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "GopikaEnglish";
  src: url("/public/fonts/GOPENGFONT.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gopika";
  src: url("/public/fonts/Gopika.woff2") format("woff2"),
    url("/public/fonts/Gopika.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Light";
  src: url("/public/fonts/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Extra Light";
  src: url("/public/fonts/Nunito-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("/public/fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Medium";
  src: url("/public/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  src: url("/public/fonts/Nunito-Bold.ttf") format("truetype");
}

html,
body,
* {
  font-family: "Nunito Medium" !important;
}

p {
  margin-left: 0px !important;
}

/* 
h2 {
  font-family: "GopikaEnglish" !important;
} */

.copy-icon {
  display: flex;
  justify-content: flex-end;
}

.output-data {
  text-align: center;
  display: flex;
  justify-content: space-around;
  font-family: "Nunito";
  margin-bottom: 10px !important;
}

.main-container {
  border-radius: 25px !important;
  box-shadow: 5px 2px 10px 10px rgba(0, 0, 0, 0.14) !important;
  margin: 20px 10px !important;
  margin: 20px auto !important;
  display: block;
  max-width: 1200px;
}

.highlight-english {
  color: red !important;
}

.Toastify__toast-body>div:last-child {
  text-align: left;
}